<template>
  <div class="layoutContainer">
    <div class="secondFloat">
      <el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
        <el-form-item label="姓名" prop="realName">
          <el-input v-model="formInline.realName" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="登录账户" prop="loginName">
          <el-input v-model="formInline.loginName" placeholder="请输入登录账号"></el-input>
        </el-form-item>
        <el-form-item label="默认角色" prop="userrole">
          <el-select v-model="formInline.userrole" clearable >
            <el-option v-for="(item,index) in userRoleList" :key="index" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">搜索</el-button>
          <el-button plain @click="reset('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableDetail">
      <el-button type="primary" icon="el-icon-plus" @click="addBut()">新增</el-button>
      <el-button type="danger"  style="background:#FF5722;" icon="el-icon-delete" @click="batchDelete()">批量删除</el-button>
      <el-upload
                class="upload-demo inline-block margin-left-10 margin-right-10"
                ref="elUpload"
                action="#"
                :show-file-list="false"
                 multiple
                :limit="1"
                :file-list="fileList"
                :before-upload="onBeforeUpload"
                :http-request="uploadFile"
                :on-change="onUploadChange"
              >
        <el-button size="min" icon="el-icon-upload" type="primary">批量上传</el-button>
      </el-upload>
      <el-button type="primary" icon="el-icon-download" @click="batchExport()">批量下载</el-button>
      <el-button plain type="primary" icon="el-icon-coin" @click="down()">下载模板</el-button>
      <el-table style="width:100%" border :data="resultList" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="45" fixed="left">
        </el-table-column>
        <el-table-column label="姓名" prop="realName" width="152"></el-table-column>
        <el-table-column label="登录账户" prop="username" width="152"></el-table-column>
        <el-table-column label="默认角色" prop="defaultRoleName" width="150"></el-table-column>
        <el-table-column label="联系人" prop="contacts" width="150"></el-table-column>
        <el-table-column label="性别" prop="sex" width="75">
          <template slot-scope="{row}">
            <span v-if="row.sex === 0">未知</span>
            <span v-if="row.sex === 1" style="color:#66ccff">男</span>
            <span v-if="row.sex === 2" style="color:#ff6699">女</span>
          </template>
        </el-table-column>
        <el-table-column label="手机号" prop="mobile" width="150"></el-table-column>
        <!-- <el-table-column label="邮箱" prop="email" width="150"></el-table-column> -->
        <!-- <el-table-column label="地址" prop="address" width="175"></el-table-column> -->
        <el-table-column label="类型" prop="userType" width="75">
          <template slot-scope="{row}">
            <span v-if="row.userType === 0">系统</span>
            <span v-if="row.userType === 1">主机厂</span>
            <span v-if="row.userType === 2">服务店</span>
           
          </template>
        </el-table-column>
         <el-table-column label="终端" prop="clientType" width="75">
          <template slot-scope="{row}">
            <span v-if="row.clientType === 1">前台</span>
            <span v-if="row.clientType === 2">后台</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="status" width="75">
          <template slot-scope="{row}">
            <span v-if="row.status === 1" style="color:#009933">启用</span>
            <span v-if="row.status === 0" style="color:#cc0000">禁用</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="300">
          <template slot-scope="{row}">
            <!-- v-if="hasPerm('menuAsimss1A2B_101')" -->
            <el-button v-if="hasPerm('menuAsimss1A2B_105')" type="primary" size="mini" @click="distriRole(row)">分配角色</el-button>
            <el-button  type="primary" size="mini" @click="initPsw(row)" style="background:#FFB800;">重置密码</el-button>
            <el-button v-hasPermission="'menuAsimss1A2B_101'"  type="primary" size="mini" @click="editopen(row)" style="background:#009688;">编辑</el-button>
            <el-button type="primary" size="mini" @click="delectClick(row)" style="background:#FF5722;">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="dataList"/>
      <!-- 新增，编辑 -->
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :before-close="handleCloseCreate">
        <!-- 新增，编辑 -->
        <el-form v-if="dialogStatus === 'edit' || dialogStatus === 'add'" ref='temp' :rules="fromTemp" :model="temp" label-position="center">
          <el-form-item label="登录账户" prop="username" :label-width="formLabelWidth">
            <el-input v-if="dialogStatus === 'add' " v-model="temp.username" placeholder="请输入账号" @input="e => temp.username= validForbid(e)" show-word-limit maxlength="20"   style="padding:0 48px 0 0"  ></el-input>
            <el-input v-if="dialogStatus === 'edit' && temp.username !== ''" v-model="temp.username" @input="e => temp.username= validForbid(e)" show-word-limit maxlength="20" :disabled="true" ></el-input>
            <el-input v-if="dialogStatus === 'edit' && temp.username === ''" v-model="temp.username" @input="e => temp.username= validForbid(e)" show-word-limit maxlength="20"   style="padding:0 48px 0 0"  ></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="realName" :label-width="formLabelWidth">
            <el-input v-model="temp.realName" placeholder="请输入姓名" @input="e => temp.realName= validForbid(e)" show-word-limit maxlength="50"  style="padding:0 48px 0 0"   ></el-input>
          </el-form-item>
          <el-form-item label="默认角色" prop="defaultRoleCode" :label-width="formLabelWidth">
            <el-select v-model="temp.defaultRoleCode" clearable  >
              <el-option v-for="(item,index) in userRoleList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系人" prop="contacts" :label-width="formLabelWidth">
            <el-input v-model="temp.contacts" @input="e => temp.contacts= validForbid(e)" show-word-limit maxlength="20"   style="padding:0 48px 0 0"  placeholder="请输入联系人"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="sex" :label-width="formLabelWidth">
            <el-radio v-model="temp.sex" :label="1">男</el-radio>
            <el-radio v-model="temp.sex" :label="2">女</el-radio>
            <el-radio v-model="temp.sex" :label="0">未知</el-radio>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile" :label-width="formLabelWidth">
            <el-input v-model="temp.mobile" maxlength="11"   style="padding:0 48px 0 0"  placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email" :label-width="formLabelWidth">
            <el-input v-model="temp.email"   style="padding:0 48px 0 0"  placeholder="请输入邮箱"></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="address"  :label-width="formLabelWidth">
            <el-input type="textarea"  rows="2" v-model="temp.address"  show-word-limit maxlength="100"  style="padding:0 48px 0 0"   placeholder="请输入地址"></el-input>
          </el-form-item>
          <el-form-item label="类型" prop="userType" :label-width="formLabelWidth">
           <el-radio-group v-model="temp.userType" @change="changeUserType">
            <el-radio :label="0" :value="0">系统</el-radio>
            <el-radio :label="1" :value="1">主机厂</el-radio>
            <el-radio :label="2" :value="2">服务店</el-radio>
           
          </el-radio-group>
          </el-form-item>
          <el-form-item label="终端" prop="clientType" :label-width="formLabelWidth">
           <el-radio-group v-model="temp.clientType" @change="changeClientType">
            <el-radio :label="1" :value="1">前台</el-radio>
            <el-radio :label="2" :value="2">后台</el-radio>
          </el-radio-group>
          </el-form-item>
          <el-form-item label="状态" prop="status" :label-width="formLabelWidth">
           <el-switch v-model="whether"></el-switch>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button type="primary" @click="dialogStatus === 'edit' ? editClick('temp') : addClick('temp')">
              立即提交
            </el-button>
            <el-button v-if="dialogStatus === 'add'"  @click="resetForm('temp')">
              重置
            </el-button>
           <el-button v-if="dialogStatus === 'edit'"  :disabled="true" @click="resetForm('temp')">
              重置
            </el-button>
            
          </el-form-item>
        </el-form>
        <!-- 分配角色 -->
        <el-form v-if="dialogStatus === 'assignRole'" ref="alloter" :model="sltUserRole">
          <el-form-item label="选择角色" :label-width="formLabelWidth" >
            <el-checkbox-group v-model="sltUserRole.roleCodeArr">
              <el-checkbox v-for=" (item, cindex) in userRoleList" :key="cindex"  :label="item.code" >{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="默认角色" prop="defaultRoleCode" :label-width="formLabelWidth">
            <el-radio-group v-model="sltUserRole.defaultRoleCode">
              <el-radio v-for=" (item, cindex) in userRoleList" :key="cindex"  :label="item.code" >{{item.name}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button type="primary" @click="updateUserRole()">
              立即提交
            </el-button>
            <el-button @click="resetCode('alloter')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { sysServerUrl } from '@/assets/js/common.js'
import Pagination from '@/components/Pagination'
import { userData, exportUser, downTemplate,userRole, userSearch, userAdd, batchDelete, userInfo, assignRoles, passwordReset, userEdit, userDel } from '@/api/api.js'
import axios from 'axios'
export default {
  name: 'layoutContainer',
  components: { Pagination },
  data () {
    return {
      formInline: {
        realName: '',
        loginName: '',
        userrole: ''
      },
      temp: {
        id: '',
        realName: '',
        username: '',
        defaultRoleName: '',
        defaultRoleCode: '',
        contacts: '',
        sex: '',
        mobile: '',
        email: '',
        address: '',
        clientType: 1,
        userType: 1,
        status: 1
      },
      perms:[],
      sltUserRole: {
        userName: '',
        defaultRoleCode: '',
        roleCodeArr: []
      },
      dialogFormVisible: false,
      formLabelWidth: '100px',
      resultList: [],
      dialogStatus: '',
      textMap: {
        edit: '用户编辑',
        add: '新增用户',
        restPwd: '重置密码',
        assignRole: '分配角色'
      },
      deleteList: [],
      pagesize: 10,
      currentPage: 1,
      total: 0,
      whether: false,
      userRoleList: [],
      uploadUrl: '',
      fileList: [],
      uploadFileList: [],
      fromTemp: {
        username: [{ required: true, message: '账号不能为空', trigger: 'blur' }],
        realName: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
        defaultRoleCode: [{ required: true, message: '默认角色不能为空', trigger: 'blur' }],
        mobile: [{ pattern: /^1\d{10}$/, message: '请输入正确的手机格式' }],
        email: [{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }]
      }
    }
  },
  // computed: {
  //   Myhead: function () {
  //     return { Authorization: sessionStorage.token }
  //   }
  // },
  methods: {
    // 列表数据
    dataList () {
      var params = {
        page: this.currentPage,
        limit: this.pagesize,
        tenantId: 0,
        realName: this.formInline.realName,
        username: this.formInline.loginName,
        defaultRoleCode: this.formInline.userrole
      } 
      userData(params).then(res=>{
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    // 搜索
    onSubmit () {
       this.currentPage=1
      var params = {
        page: this.currentPage,
        limit: this.pagesize,
        tenantId: 0,
        realName: this.formInline.realName,
        username: this.formInline.loginName,
        defaultRoleCode: this.formInline.userrole
      }
      userSearch(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    // 角色信息
    getUserRoleList () {
      userRole().then(res=>{
        this.userRoleList = res.data.data
      })
    },
    resetTemp () {
      this.whether = true

      this.temp = {
        id: '',
        realName: '',
        username: '',
        defaultRoleName: '',
        defaultRoleCode: '',
        contacts: '',
        sex: 0,
        mobile: '',
        email: '',
        address: '',
        clientType: 1,
        userType: 1,
        status: 1
      }
    },
    selectState () {
      if (this.whether === false) {
        this.temp.status = 0
      } else {
        this.temp.status = 1
      }
 
    },
    handleCloseCreate () {
      this.dialogFormVisible = false
      if (this.dialogStatus === 'add') {
        this.$refs.temp.resetFields()
      }
    },
    changeClientType(val){
        this.temp.clientType=val
    },
    changeUserType(val){
        this.temp.userType=val
    },
    // 新增
    addBut () {
      this.resetTemp()
      this.temp.clientType=1
      this.temp.userType=0
      this.temp.status=1
      this.whether=true
      this.dialogStatus = 'add'
      this.dialogFormVisible = true
    },
    addClick (temp) {
      this.$refs[temp].validate((valid) => {
        if (valid) {
          this.selectState()
          var params = new URLSearchParams()
          params.append('realName', this.temp.realName)
          params.append('username', this.temp.username)
          params.append('defaultRoleCode', this.temp.defaultRoleCode)
          params.append('email', this.temp.email)
          params.append('address', this.temp.address)
          params.append('contacts', this.temp.contacts)
          params.append('mobile', this.temp.mobile)
          params.append('status', this.temp.status)
          params.append('userType', this.temp.userType)
          params.append('clientType', this.temp.clientType)
          params.append('sex', this.temp.sex)
          userAdd(params).then(res => {
            if(res.data.code === 100){
              this.$message({
                type: 'success',
                message: '添加成功'
              })
              this.dataList()
              this.dialogFormVisible = false
            }else{
              this.$message.error(res.data.msg)
            }
          })
        } else {
          this.$message.error('请完善用户信息')
        }
      })
    },
    resetForm (temp) {
      if (this.$refs[temp].resetFields() !== undefined) {
        this.$refs[temp].resetFields()
        this.whether=true
      }
    },
    reset (formInline) {
      if (this.$refs[formInline].resetFields() !== undefined) {
        this.$refs[formInline].resetFields()
      }
      this.dataList()
    },
    // 批量删除
    handleSelectionChange (val) {
      this.deleteList = val
    },
    batchDelete () {
      var list = []
      this.deleteList.forEach(function (item) {
        list.push(item.id)
      })
      var params = new URLSearchParams()
      params.append('ids', JSON.stringify(list))
      this.$confirm('确定批量删除选中的行', '批量删除用户', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        batchDelete(params).then(res=>{
          if (res.data.code === 100) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.dataList()
          }else{
            this.$message.error(res.data.msg)
          }
        })
      }).catch((error) => {
        this.$message.error('删除失败')
      })
    },
    // 附件上传
    onBeforeUpload(file) {
      var fileExt =file.name.substring(file.name.lastIndexOf('.')+1).toLowerCase();  
      var text=""               
      const docExt = fileExt === 'xls' 
      const docxExt = fileExt === 'xlsx' 
      const isLimit = file.size / 1024 / 1024 < 2  
      if(!docxExt) { 
        text="上次文件只能是xlsx格式!";
        this.$message.warning(text)
        return false;
      }  
      if (!isLimit) {  
        text="上传文件大小不能超过 2MB!";
        this.$message.warning(text)
        return false;
      }  
      return true;
    },
    onUploadChange(file){
      //this.files.push(file.raw) 
      console.log("onUploadChange")
    },
    // 批量上传
    uploadFile (param) {
        var _this= this
			  var formData = new FormData(); 
			  formData.append('file', param.file);
        axios({
          method: 'post',
          url: sysServerUrl + 'sys/user/batchImport',
          data: formData
        }).then(res => {
          if (res.data.code === 100) {
          
           	 this.$message({
                type: 'success',
                message: '批量导入成功'
              })
          this.dataList()

          }else{
            _this.$alert(res.data.msg,'信息提示',{dangerouslyUseHTMLString:true})
          }
          _this.fileList = []
          
        }).catch(function(error){
            _this.fileList = []
           _this.$alert('系统出现异常，导入失败','信息提示',{dangerouslyUseHTMLString:true})
        })
			 
		},
     handlesuccess (file, fileList) {
      this.form.image = file.data.fileName
    },
    beforeAvatarUpload (file) {
      const extension = file.name.split('.')[1] === 'png'
      const extension2 = file.name.split('.')[1] === 'jpg'
      const extension3 = file.name.split('.')[1] === 'jpeg'
      const extension4 = file.name.split('.')[1] === 'gif'
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!extension && !extension2 && !extension3 && !extension4) {
        this.$message.warning('上传模板只能是 png、jpg、jpeg、gif格式!')
      }
      if (!isLt2M) {
        this.$message.warning('上传模板大小不能超过 5MB!')
      }
      // return isLt2M
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    handlePreview (file) {
      console.log(file)
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    uploadFileError(err, file, fileList) {
     
      console.log(err)
      this.$message.error('导入失败')
      this.$refs.upload.clearFiles();
    },
    // 批量下载
    batchExport () {
         //window.location.href= sysServerUrl   + "sys/user/batchExport?username=''"
      var params ='?username='+ ''; 
      exportUser(params).then(res => {
                  console.log(res)
                  if(!res.data){
                       return
                   }
                  var name = "用户列表统计.xlsx";
                  var blob = new Blob([res.data]);
                  var url = window.URL.createObjectURL(blob);
                  var aLink = document.createElement("a");
                  aLink.style.display = "none";
                  aLink.href = url;
                  aLink.setAttribute("download", name);
                  document.body.appendChild(aLink);
                  aLink.click();
                  document.body.removeChild(aLink); //下载完成移除元素
                  window.URL.revokeObjectURL(url); //释放掉blob对象
                })
     },
    // 下载模板
    down () {
       //window.location.href = sysServerUrl  + 'static/excel/用户导入模板.xlsx'
      var params =''; 
      downTemplate(params).then(res => {
                  console.log(res)
                  if(!res.data){
                       return
                   }
                  var name = "用户导入模板.xlsx";
                  var blob = new Blob([res.data]);
                  var url = window.URL.createObjectURL(blob);
                  var aLink = document.createElement("a");
                  aLink.style.display = "none";
                  aLink.href = url;
                  aLink.setAttribute("download", name);
                  document.body.appendChild(aLink);
                  aLink.click();
                  document.body.removeChild(aLink); //下载完成移除元素
                  window.URL.revokeObjectURL(url); //释放掉blob对象
                })

    },
    // 分配角色
    distriRole (row) {
      this.dialogStatus = 'assignRole'
      this.dialogFormVisible = true
      var params ='?id='+ row.id
      userInfo(params).then(res => {
        this.sltUserRole.defaultRoleCode = res.data.data.defaultRoleCode
        this.sltUserRole.userName =res.data.data.username;
        this.sltUserRole.roleCodeArr = res.data.data.roleList
        var roleCodeArr= res.data.data.roleList
        //获取当前用户选择的角色
        if(roleCodeArr!=null&&roleCodeArr.length>0){
          if(this.userRoleList.length>0){
            for(var i=0;i<this.userRoleList.length;i++){
              var curRoleCode= this.userRoleList[i].code
              if(roleCodeArr.indexOf(curRoleCode) > -1){
                if(this.sltUserRole.roleCodeArr.indexOf(curRoleCode)==-1){
                  this.sltUserRole.roleCodeArr.push(curRoleCode)
                }
              }
            }
          }
        }
      })
    },
    updateUserRole () {
      var roleCode= this.sltUserRole.defaultRoleCode
      var sltRoleCodeArr=this.sltUserRole.roleCodeArr
     
      if(sltRoleCodeArr.length==0){
        this.$message({
          type: 'error',
          message: '请选择角色'
        })
        return false 
      }

      if(roleCode==''){
        this.$message({
          type: 'error',
          message: '请选择默认角色'
        })
        return false 
      }
        
      var params = new FormData()
			params.append('userName', this.sltUserRole.userName)
      params.append('code', sltRoleCodeArr)
      params.append('defultCode', roleCode)
      assignRoles(params).then(res=>{
        if(res.data.code === 100){
          this.$message({
            type: 'success',
            message: '分配成功'
          })
          this.dataList()
          this.dialogFormVisible = false
        }
      }).catch(function(error){
        this.$message({
          type: 'error',
          message: '分配失败'
        })
      })
    },
    resetCode (temp) {
      if (this.$refs[temp].resetFields() !== undefined) {
        this.$refs[temp].resetFields()
      }
    },
    // 密码重置
    initPsw (row) {   
      var params = '?id=' + row.id
      passwordReset(params).then(res => {
        if(res.data.code === 100){
          this.$message({
            type: 'success',
            message: res.data.msg
          })
        }else if(res.data.code === 101){
          this.$message({
            type: 'error',
            message: res.data.msg
          })
        }else {
          this.$message({
            type: 'error',
            message: res.data.msg
          })
        }
      })
    },
    // 编辑
    editopen (row) {
      this.resetTemp()
      this.temp = Object.assign({}, row)
      this.dialogStatus = 'edit'
      this.dialogFormVisible = true
      if (row.status === 0) {
        this.whether = false
      } else {
        this.whether = true
      }

      if (row === null || row.sex === null) {
        this.temp.sex = 0
      } else {
        this.temp.sex = row.sex
      }
     
    },
    // 提交编辑
    editClick (temp) {
      this.$refs[temp].validate((valid) => {
        if (valid) {
          this.selectState()
          var params = {
            id: this.temp.id,
            realName: this.temp.realName,
            username: this.temp.username,
            defaultRoleCode: this.temp.defaultRoleCode,
            contacts: this.temp.contacts,
            sex: this.temp.sex,
            mobile: this.temp.mobile,
            email: this.temp.email,
            address: this.temp.address,
            userType: this.temp.userType,
            clientType: this.temp.clientType,
            status: this.temp.status
          }
          userEdit(params).then(res => {
            if(res.data.code === 100){
              this.$message({
                message: '保存成功',
                type: 'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          this.$message.error('请完善用户信息')
        }
      })
    },
    // 删除
    delectClick (row) {
      this.$confirm('确定删除 ' + row.realName + '?', '删除用户', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        userDel(row.id).then(res=>{
          if(res.data.code === 100){
            this.$message({
              type:'success',
              message: '删除成功'
            })

           if(this.resultList!=null&&this.resultList.length==1){
              this.currentPage =this.currentPage-1
            }
            this.dataList()
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      }).catch((error) => {
        this.$message.error('删除失败')
      })
    }
  },
  mounted () {
    this.uploadUrl = sysServerUrl + 'sys/upload/attach?flag=carTrain'
   // let tt= this.$store.state.perms.indexOf('menuAsimss1A2B_105')
   // console.log(tt)
    this.dataList()
    this.getUserRoleList()
  }
}
</script>
<style>
  .inline-block{
    display: inline-block
  }
  .margin-right-10{
    margin-right: 10px;
  }
  .margin-left-10{
    margin-left: 10px;
  }
</style>
